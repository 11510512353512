import baseAPI from './base';

const resource = 'wallet';

const deposit = async(coin) => {
    const response = await baseAPI().get(`${resource}/deposit`, {
        params: {
            coin,
        },
    });
    return response.data;
};

const swap = async(amount, coin_from, coin_to, token) => {
    const response = await baseAPI().post(`${resource}/swap-ebp`, {
        amount,
        coin_from,
        coin_to,
        token,
    });
    return response.data;
};

const withdraw = async(address, amount, ecosystem, auth, coin_to, token) => {
    const response = await baseAPI().post(`${resource}/withdraw-ebp`, {
        address,
        amount,
        ecosystem,
        auth,
        coin_to,
        // coin_to,
        token,
    });
    return response.data;
};

const transfer = async(email, amount, auth, coin, token) => {
    const response = await baseAPI().post(`${resource}/transfer`, {
        email,
        amount,
        auth,
        coin,
        token,
    });
    return response.data;
};
export default {
    deposit,
    withdraw,
    transfer,
    swap
};