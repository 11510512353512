<template>
  <div>
    <div class="block-title">
      <h2>Swap {{ coinName }}</h2>
    </div>
    <div class="row">
      <ValidationObserver v-slot="{ invalid }">
        <form class="form-horizontal" style="padding-top: 15px">
          <div class="form-group">
            <div class="col-xs-12">
              <label class="col-md-3 control-label" for="coinType">Coin from</label>
              <div class="col-md-6">
                <select :value="coinId" name="coinType" class="form-control" size="1" disabled>
                  <option value="3">EUSD</option>
                  <option value="8">EBP (BEP-20)</option>
                </select>
              </div>
            </div> 
          </div>
          <div class="form-group">
            <div class="col-xs-12">
              <label class="col-md-3 control-label" for="coinType">Coin to</label>
              <div class="col-md-6">
                <select :value="coinfrom" name="coinType" class="form-control" size="1" disabled>
                  <option value="3">EUSD</option>
                  <option value="8">EBP</option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="col-xs-12">
              <label class="col-md-3 control-label" for="coinType">Max</label>
              <div class="col-md-6">
                <input
                    :value="TruncateToDecimals(coins[coinKey].MaxSwap / coinRate + 0, '', 4)"
                    type="text"
                    name="max"
                    class="form-control"
                    readonly
                    placeholder="Amount"
                  />
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="col-xs-12">
              <label class="col-md-3 control-label" for="coinType">Min</label>
              <div class="col-md-6">
                <input
                    :value="TruncateToDecimals(coins[coinKey].MinSwap / coinRate + 0, '', 4)"
                    type="text"
                    name="min"
                    readonly
                    class="form-control"
                    placeholder="Amount"
                  />
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="col-xs-12">
              <label class="col-md-3 control-label" for="coinType">Rate</label>
              <div class="col-md-6">
                <input
                    :value="TruncateToDecimals(coinRate, '', 4)"
                    type="text"
                    name="max"
                    class="form-control"
                    readonly
                    placeholder="Amount"
                  />
              </div>
            </div>
          </div>
          <ValidationProvider :rules="`required|min_value:${coins[coinKey].MinSwap / coinRate}`" v-slot="{ errors }" tag="div">
            <div class="form-group" :class="{ 'has-error': errors[0] }">
              <div class="col-xs-12">
                <label class="col-md-3 control-label" for="amount">Amount</label>
                <div class="col-md-6">
                  <input
                    v-model.number="amount"
                    type="text"
                    name="amount"
                    v-on:keyup="keyupChangeAmount(coinKey, coinRate)"
                    class="form-control"
                    placeholder="Amount"
                  />
                  <div v-show="errors[0]" class="animation-slideUp help-block clear-both" >
                    {{ errors[0] }}
                  </div>
                </div>
                
              </div>
            </div>
          </ValidationProvider>
          <div class="form-group">
            <div class="col-xs-12">
              <label class="col-md-3 control-label" for="coinType">Fee({{coins[coinKey].FeeSwap*100}}%)</label>
              <div class="col-md-6">
                <input
                    :value="TruncateToDecimals(amountFee, '', 4)"
                    type="text"
                    name="feee"
                    class="form-control"
                    readonly
                    placeholder="Amount"
                  />
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="col-xs-12">
              <label class="col-md-3 control-label" for="coinType">Actually received</label>
              <div class="col-md-6">
                <input
                    :value="TruncateToDecimals(amountReceive, '', 4)"
                    type="text"
                    name="amountReceived"
                    class="form-control"
                    readonly
                    placeholder="Amount"
                  />
              </div>
              <p
          style="padding-top: 15px;
                font-size: 14px;
                line-height: 1.3;
                text-align: center;
                color: red;
                max-width: 600px;
                margin: auto;
                padding-bottom: 10px;
                clear: both;"
        >
          The exact displayed amount received from the exchange rate when swapping EUSD to EBP is the expected amount only. Therefore, the exact number received may not differ significantly when receiving
        </p>
            </div>
          </div>
          <div class="form-group">
            <div class="col-xs-12 text-center">
              <button
                type="button"
                class="btn btn-sm btn-info"
                :disabled="invalid"
                @click="handleSwap"
              >
                Swap coin
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import walletAPI from "@/services/wallet";
import { mapGetters } from "vuex";

export default {
  name: "SwapForm",
  props: {
    coinId: {
      type: Number,
      default: 3
    },
    coinName: String,
    coinKey: String,
    coinRate: Number,
  },
  data() {
    return {
      amount: 0,
      toUser: null,
      password: null,
      amountReceive: 0,
      amountTo: 0,
      amountFee: 0,
    };
  },
  computed: {
    ...mapGetters({
      coins: "wallet/ListCoin"
    }),
    coinfrom: function() {
      if (this.coinId == 3) {
        return 8;
      } else {
        return 3;
      }
    }
  },
  created() {
    this.$store.dispatch("wallet/req_getCoin");
    console.log(this.coins,123);
  },
  methods: {
    TruncateToDecimals(num, mah, dec = 2) {
      // eslint-disable-next-line no-undef
      var numeral = require("numeral");
      var calcDec = Math.pow(10, dec);
      var newNum = Math.trunc(num * calcDec) / calcDec;
      const d = newNum.toString().split(".");
      return `${numeral(d[0]).format(0, 0)}${d[1] ? "." + d[1] : ""}`;
    },
    keyupChangeAmount: function(coinKey, CoinRate){
      if(coinKey == 'EBP'){
        this.amountTo = this.amount * CoinRate;
      }else{
        this.amountTo = this.amount / CoinRate;
      }
      this.amountFee = this.amountTo * this.coins[coinKey].FeeSwap;
      this.amountReceive = this.amountTo - this.amountFee;
      //console.log(this.amount, this.coins[coinKey].FeeSwap);
    },
    async handleSwap() {
      this.$startLoading();
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("swap");
      const { data, status, message, errors } = await walletAPI.swap(
        this.amount,
        this.coinId,
        this.coinfrom,
        token
      );
      this.$store.dispatch("wallet/req_getHistory");
      this.$store.dispatch("dashboard/req_getDashboard", 1);
      this.$finishLoading();

      // if (status) {
      //   this.$toast.success(message, {});
      // } else {
      //   message ? this.$toast.error(message, {}) : this.$toast.error(errors, {});
      // }
    }
  }
};
</script>
<style>
.clear-both{
  clear: both;
}
</style>