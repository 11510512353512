<template>
  <div>
    <div class="block-title">
      <h2>Deposit {{ coinName }}</h2>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <p
          style="    padding-top: 15px;
    font-size: 16px;
    line-height: 1.3;
    text-align: center;
    color: red;
    max-width: 600px;
    margin: auto;
    padding-bottom: 10px;"
        >
          We only send {{ coinName }} to this deposit wallet address. If you send another coin or
          token to this address, you can risk losing money.
        </p>
      </div>
      <div class="col-xs-12">
        <input ref="address-code" v-model="address" type="text" class="form-control" readonly />
      </div>
      <div class="col-xs-12 text-center" v-show="toggleQR">
        <img :data-src="coin.Qr" :src="coin.Qr" width="350" height="350" />
      </div>
    </div>
    <div class="row text-center deposit-btn-group">
      <div class="btn-group">
        <button type="button" class="btn btn-primary" @click="handleCopy">
          <i class="fa fa-clone"></i> Copy
        </button>
        <button type="button" class="btn btn-success" @click="toggleQR = !toggleQR">
          <i class="fa fa-qrcode"></i> Show QR code
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import walletAPI from "@/services/wallet";

export default {
  name: "DepositForm",
  props: {
    coinId: Number,
    coinName: String
  },
  data: () => ({
    coin: {},
    toggleQR: false
  }),
  async created() {
    this.$startLoading();
    try {
      const response = await walletAPI.deposit(this.coinId);
      this.coin = response.data;
    } catch (err) {}
    this.$finishLoading();
  },
  computed: {
    address() {
      return this.coin.address;
    }
  },
  methods: {
    handleCopy() {
      this.$refs["address-code"].select();
      document.execCommand("copy");
      alert(`Address copied: ${this.address}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.deposit-btn-group {
  padding-bottom: 20px;
  padding-top: 14px;
}
</style>
