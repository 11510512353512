<template>
  <div>
    <div class="block-title">
      <h2>Transfer {{ coinName }}</h2>
    </div>
    <div class="row">
      <ValidationObserver v-slot="{ invalid }">
        <form class="form-horizontal">
          <div class="form-group">
            <div class="col-xs-12">
              <label class="col-md-3 control-label" for="coinType">Coin</label>
              <div class="col-md-3">
                <select :value="coinId" name="coinType" class="form-control" size="1" disabled>
                  <option value="1">BTC</option>
                  <option value="2">ETH</option>
                  <option value="5">USDT</option>
                  <option value="3">EUSD</option>
                  <option value="8">EBP</option>
                </select>
              </div>
            </div>
          </div>
          <ValidationProvider rules="required" v-slot="{ errors }" tag="div">
            <div class="form-group" :class="{ 'has-error': errors[0] }">
              <div class="col-xs-12">
                <label class="col-md-3 control-label" for="address">User Receive</label>
                <div class="col-md-6">
                  <input
                    v-model="toUser"
                    autocomplete="off"
                    type="email"
                    name="number"
                    class="form-control"
                    placeholder="user email receive"
                  />
                </div>
                <div v-show="errors[0]" class="animation-slideUp help-block">
                  {{ errors[0] }}
                </div>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider rules="required|min_value:10" v-slot="{ errors }" tag="div">
            <div class="form-group" :class="{ 'has-error': errors[0] }">
              <div class="col-xs-12">
                <label class="col-md-3 control-label" for="amount">Amount</label>
                <div class="col-md-3">
                  <input
                    v-model.number="amount"
                    type="text"
                    name="amount"
                    class="form-control"
                    placeholder="Amount"
                  />
                </div>
                <div v-show="errors[0]" class="animation-slideUp help-block">
                  {{ errors[0] }}
                </div>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider rules="required|max:12" v-slot="{ errors }" tag="div">
            <div class="form-group" :class="{ 'has-error': errors[0] }">
              <div class="col-xs-12">
                <label class="col-md-3 control-label" for="amount">Google authenticator</label>
                <div class="col-md-3">
                  <input
                    v-model="auth"
                    type="number"
                    name="amount"
                    class="form-control"
                    placeholder="Google authenticator code"
                  />
                </div>
                <div v-show="errors[0]" class="animation-slideUp help-block">
                  {{ errors[0] }}
                </div>
              </div>
            </div>
          </ValidationProvider>
          <div class="form-group">
            <div class="col-xs-12 text-center">
              <button
                type="button"
                class="btn btn-sm btn-info"
                :disabled="invalid"
                @click="handleTransfer"
              >
                Transfer
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import walletAPI from "@/services/wallet";

export default {
  name: "TransferFrom",
  props: {
    coinId: Number,
    coinName: String
  },
  data() {
    return {
      amount: 10,
      toUser: null,
      auth: null,
      coin: coinId
    };
  },
  methods: {
    async handleTransfer() {
      this.$startLoading();
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("deposit");
      const { data, status, message, errors } = await walletAPI.transfer(
        this.toUser,
        this.amount,
        this.auth,
        this.coinId,
        token
      );
      this.$store.dispatch("wallet/req_getHistory");
      this.$store.dispatch("dashboard/req_getDashboard", 1);
      this.$finishLoading();

      // if (status) {
      //   this.$toast.success(message, {});
      // } else {
      //   message ? this.$toast.error(message, {}) : this.$toast.error(errors, {});
      // }
    }
  }
};
</script>
