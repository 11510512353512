<template>
  <div>
    <div class="block-title">
      <h2>Withdraw {{ coinName }}</h2>
    </div>
    <div class="row">
      <ValidationObserver v-slot="{ invalid }">
        <form class="form-horizontal" style="padding-top: 15px">
          <div class="form-group">
            <div class="col-xs-12">
              <label class="col-md-3 control-label" for="coinType">Coin To</label>
              <div class="col-md-6">
                <select :value="coinId" name="coin_to" class="form-control" size="1" disabled>
                  <option :value="coin.ID" v-for="coin in coins" :key="coin.ID">{{
                    coin.Name
                  }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="col-xs-12">
              <label class="col-md-3 control-label" for="coinType">Ecosystem</label>
              <div class="col-md-6">
                <select
                  v-model="ecosystem"
                  name="ecosystem"
                  @change="onChangeEcosystem($event)"
                  class="form-control"
                >
                  <option value="">-- Select --</option>
                  <option :value="item.id" v-for="item in ecosystems" :key="item.id">{{
                    item.name
                  }}</option>
                </select>
              </div>
            </div>
          </div>
          <!-- <div class="form-group">
            <div class="col-xs-12">
              <label class="col-md-3 control-label" for="coinType">Coin To</label>
              <div class="col-md-3">
                <select v-model="coin_to" name="coin_to" class="form-control" size="1">
                  <option :value="coin.ID" v-for="coin in coins[coinId].Withdraw" :key="coin.key">{{
                    coin.Name
                  }}</option>
                </select>
              </div>
            </div>
          </div> -->
          <ValidationProvider rules="required|alpha_num" v-slot="{ errors }" tag="div">
            <div class="form-group" :class="{ 'has-error': errors[0] }">
              <div class="col-xs-12">
                <label class="col-md-3 control-label" for="address">Address</label>
                <div class="col-md-6">
                  <input
                    v-model="address"
                    autocomplete="off"
                    type="text"
                    name="address"
                    class="form-control"
                    placeholder="Address"
                  />
                </div>
                <div v-show="errors[0]" class="animation-slideUp help-block">{{ errors[0] }}</div>
              </div>
            </div>
          </ValidationProvider>
          <div class="form-group">
            <div class="col-xs-12">
              <label class="col-md-3 control-label" for="coinType">Min</label>
              <div class="col-md-6">
                <input
                    :value="TruncateToDecimals(coins[coinKey].WithdrawMin / coinRate + 0, '', 4)"
                    type="text"
                    name="min"
                    readonly
                    class="form-control"
                    placeholder="Amount"
                  />
              </div>
            </div>
          </div><div class="form-group">
            <div class="col-xs-12">
              <label class="col-md-3 control-label" for="coinType">Rate</label>
              <div class="col-md-6">
                <input
                    :value="TruncateToDecimals(coinRate + 0, '', 4)"
                    type="text"
                    name="min"
                    readonly
                    class="form-control"
                    placeholder="Amount"
                  />
              </div>
            </div>
          </div>
          <ValidationProvider :rules="`required|min_value:${coins[coinKey].WithdrawMin / coinRate}`" v-slot="{ errors }" tag="div">
            <div class="form-group" :class="{ 'has-error': errors[0] }">
              <div class="col-xs-12">
                <label class="col-md-3 control-label" for="amount">Amount</label>
                <div class="col-md-6">
                  <input
                    v-model.number="amount"
                    v-on:keyup="changeAmount"
                    type="text"
                    name="amount"
                    class="form-control"
                    placeholder="Amount"
                  />
                  <div v-show="errors[0]" class="animation-slideUp help-block clear-both" >
                    {{ errors[0] }}
                  </div>
                </div>
              </div>
            </div>
          </ValidationProvider>
          <div class="form-group">
            <div class="col-xs-12">
              <label class="col-md-3 control-label" for="fee">Fee ({{ fee }}%)</label>
              <div class="col-md-6">
                <input
                  v-model.number="amountFee"
                  type="text"
                  name="fee"
                  class="form-control"
                  placeholder="fee"
                />
              </div>
            </div>
          </div>
          <ValidationProvider rules="required|max:12" v-slot="{ errors }" tag="div">
            <div class="form-group" :class="{ 'has-error': errors[0] }">
              <div class="col-xs-12">
                <label class="col-md-3 control-label" for="amount">Google authenticator</label>
                <div class="col-md-6">
                  <input
                    v-model="auth"
                    type="number"
                    name="amount"
                    class="form-control"
                    placeholder="Google authenticator code"
                  />
                  <div v-show="errors[0]" class="animation-slideUp help-block clear-both" >
                    {{ errors[0] }}
                  </div>
                </div>
              </div>
            </div>
          </ValidationProvider>
          <div class="form-group">
            <div class="col-xs-12 text-center">
              <button
                type="button"
                class="btn btn-sm btn-info"
                :disabled="invalid"
                @click="handleWithdraw"
              >
                Withdraw
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import walletAPI from "@/services/wallet";
import { mapGetters } from "vuex";

export default {
  name: "WithdrawForm",
  props: {
    coinId: Number,
    coinName: String,
    coinKey: String,
    coinRate: Number,
    feeEcosystems: 0
  },
  data: () => ({
    address: "",
    amount: 10,
    coinID: "",
    coin_to: "",
    auth: "",
    ecosystem: "",
    fee: 0,
    amountFee: 0
  }),
  computed: {
    ...mapGetters({
      coins: "wallet/ListCoin",
      ecosystems: "wallet/ListEcosystem"
    })
  },
  created() {
    this.$store.dispatch("wallet/req_getListEcosystem");
  },
  methods: {
    TruncateToDecimals(num, mah, dec = 2) {
      // eslint-disable-next-line no-undef
      var numeral = require("numeral");
      var calcDec = Math.pow(10, dec);
      var newNum = Math.trunc(num * calcDec) / calcDec;
      const d = newNum.toString().split(".");
      return `${numeral(d[0]).format(0, 0)}${d[1] ? "." + d[1] : ""}`;
    },
    onChangeEcosystem(event) {
      this.fee = this.feeEcosystems.WithdrawFeeEcoSystem[event.target.value];
      this.amountFee = (this.amount * this.fee) / 100;
    },
    changeAmount: function(event) {
      this.amount = event.target.value;
      this.amountFee = (this.amount * this.fee) / 100;
    },
    async handleWithdraw() {
      var WAValidator = require("multicoin-address-validator");
      var mainnet = "";
      switch (this.coinId) {
        case 3:
          mainnet = "trx";
          break;
        case 5:
          mainnet = "eth";
          break;
        case 6:
          mainnet = "trx";
          break;
        case 8:
          mainnet = "eth";
          break;
      }
      var valid = WAValidator.validate(this.address, mainnet);
      if (valid) {
        this.$startLoading();
        await this.$recaptchaLoaded();
        const token = await this.$recaptcha("deposit");
        const { data, status, message, errors } = await walletAPI.withdraw(
          this.address,
          this.amount,
          this.ecosystem,
          this.auth,
          this.coinId.toString(),
          token
        );
        this.$finishLoading();
        this.$store.dispatch("wallet/req_getHistory");
        this.$store.dispatch("dashboard/req_getDashboard");
      } else {
        alert("This is an invalid address!");
      }
    }
  }
};
</script>
