<template>
  <base-layout>
    <statistic-panel></statistic-panel>

    <div class="row">
      <div class="col-md-4">
        <div class="block">
          <div class="table-responsive">
            <table
              class="table table-vcenter table-bordered table-hover table-striped table-condensed"
            >
              <thead>
                <tr>
                  <th>Coin</th>
                  <th class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="coin in coins" :key="coin.id">
                  <td>{{ coin.Name }}</td>
                  <td class="text-center">
                    <div class="btn-group">
                      <a
                        v-if="coin.Deposit == true"
                        data-toggle="tooltip"
                        title="Deposit"
                        class="btn btn-info btn-sm"
                        @click="handleDeposit(coin.ID, coin.Name)"
                      >
                        Deposit
                      </a>
                      <a
                        v-if="coin.Withdraw.length > 0"
                        data-toggle="tooltip"
                        title="Withdraw"
                        class="btn btn-warning btn-sm"
                        @click="handleWithdraw(coin.ID, coin.Name, coin.Key, coin.rate)"
                      >
                        Withdraw
                      </a>

                      <a
                        v-if="coin.Transfer == true"
                        data-toggle="tooltip"
                        title="transfer"
                        class="btn btn-primary btn-sm"
                        @click="handleTransfer(coin.ID, coin.Name)"
                      >
                        Transfer
                      </a>
                      <a
                        v-if="coin.Swap == true"
                        data-toggle="tooltip"
                        title="transfer"
                        class="btn btn-success btn-sm"
                        @click="handleSwap(coin.ID, coin.Name, coin.Key, coin.rate)"
                      >
                        Swap
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div v-show="form" class="block">
          <keep-alive>
            <component
              :is="currentForm"
              :coinId="coinId"
              :coinName="coinName"
              :coinKey="coinKey"
              :coinRate="coinRate"
              :feeEcosystems="ecosystems"
              :key="`${currentForm}_${coinId}`"
            ></component>
          </keep-alive>
        </div>
        <div class="block">
          <transaction-histories></transaction-histories>
        </div>
      </div>
    </div>
  </base-layout>
</template>

<script>
import { mapGetters } from "vuex";
import StatisticPanel from "@/components/dashboard/StatisticPanel";
import TransactionHistories from "@/components/transactionDetail/ListItem";
import WalletDeposit from "@/components/wallet/DepositForm";
import WalletWithdraw from "@/components/wallet/WithdrawForm";
import WalletTransfer from "@/components/wallet/TransferForm";
import WalletSwap from "@/components/wallet/SwapForm";
import BaseLayout from "./layouts/Base";

export default {
  name: "Wallet",
  components: {
    BaseLayout,
    StatisticPanel,
    TransactionHistories,
    WalletDeposit,
    WalletWithdraw,
    WalletTransfer,
    WalletSwap
  },
  data: () => ({
    // coins: [],
    form: "",
    coinId: 5,
    coinRate: 0,
    coinName: "",
    coinKey: "",
    ecosystems: []
  }),

  computed: {
    ...mapGetters({
      coins: "wallet/ListCoin"
    }),
    currentForm() {
      return this.form ? `wallet-${this.form}` : "div";
    }
    // coinName() {
    //   const coin = this.coins.find(coin => coin.ID === this.coinId);
    //   return coin.name;
    // }
  },
  methods: {
    handleDeposit(coinId, coinName) {
      this.form = "deposit";
      this.coinId = coinId;
      this.coinName = coinName;
    },
    handleWithdraw(coinId, coinName, coinKey, coinRate) {
      this.form = "withdraw";
      this.coinId = coinId;
      this.coinName = coinName;
      this.coinRate = coinRate;
      this.coinKey = coinKey;
      this.ecosystems = this.coins[coinKey];
    },
    handleTransfer(coinId, coinName) {
      this.form = "transfer";
      this.coinId = coinId;
      this.coinName = coinName;
    },
    handleSwap(coinId, coinName, coinKey, coinRate) {
      this.form = "swap";  
      this.coinId = coinId;
      this.coinName = coinName;
      this.coinRate = coinRate;
      this.coinKey = coinKey;
      console.log(this.coinId,this.coinName, this.coinKey);
    }
  },
  created() {
    this.$store.dispatch("wallet/req_getCoin");
  }
};
</script>
