var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"block-title"},[_c('h2',[_vm._v("Transfer "+_vm._s(_vm.coinName))])]),_c('div',{staticClass:"row"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"form-horizontal"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"col-xs-12"},[_c('label',{staticClass:"col-md-3 control-label",attrs:{"for":"coinType"}},[_vm._v("Coin")]),_c('div',{staticClass:"col-md-3"},[_c('select',{staticClass:"form-control",attrs:{"name":"coinType","size":"1","disabled":""},domProps:{"value":_vm.coinId}},[_c('option',{attrs:{"value":"1"}},[_vm._v("BTC")]),_c('option',{attrs:{"value":"2"}},[_vm._v("ETH")]),_c('option',{attrs:{"value":"5"}},[_vm._v("USDT")]),_c('option',{attrs:{"value":"3"}},[_vm._v("EUSD")]),_c('option',{attrs:{"value":"8"}},[_vm._v("EBP")])])])])]),_c('ValidationProvider',{attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'has-error': errors[0] }},[_c('div',{staticClass:"col-xs-12"},[_c('label',{staticClass:"col-md-3 control-label",attrs:{"for":"address"}},[_vm._v("User Receive")]),_c('div',{staticClass:"col-md-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.toUser),expression:"toUser"}],staticClass:"form-control",attrs:{"autocomplete":"off","type":"email","name":"number","placeholder":"user email receive"},domProps:{"value":(_vm.toUser)},on:{"input":function($event){if($event.target.composing){ return; }_vm.toUser=$event.target.value}}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"animation-slideUp help-block"},[_vm._v(" "+_vm._s(errors[0])+" ")])])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|min_value:10","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'has-error': errors[0] }},[_c('div',{staticClass:"col-xs-12"},[_c('label',{staticClass:"col-md-3 control-label",attrs:{"for":"amount"}},[_vm._v("Amount")]),_c('div',{staticClass:"col-md-3"},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.amount),expression:"amount",modifiers:{"number":true}}],staticClass:"form-control",attrs:{"type":"text","name":"amount","placeholder":"Amount"},domProps:{"value":(_vm.amount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.amount=_vm._n($event.target.value)},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"animation-slideUp help-block"},[_vm._v(" "+_vm._s(errors[0])+" ")])])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|max:12","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'has-error': errors[0] }},[_c('div',{staticClass:"col-xs-12"},[_c('label',{staticClass:"col-md-3 control-label",attrs:{"for":"amount"}},[_vm._v("Google authenticator")]),_c('div',{staticClass:"col-md-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.auth),expression:"auth"}],staticClass:"form-control",attrs:{"type":"number","name":"amount","placeholder":"Google authenticator code"},domProps:{"value":(_vm.auth)},on:{"input":function($event){if($event.target.composing){ return; }_vm.auth=$event.target.value}}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"animation-slideUp help-block"},[_vm._v(" "+_vm._s(errors[0])+" ")])])])]}}],null,true)}),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"col-xs-12 text-center"},[_c('button',{staticClass:"btn btn-sm btn-info",attrs:{"type":"button","disabled":invalid},on:{"click":_vm.handleTransfer}},[_vm._v(" Transfer ")])])])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }